<template>
  <div>
    <div>
      <b-card>
        <div class="w-100 text-uppercase">
          <h4 class="mb-3" v-if="!isEdit">Add New {{ isService ? 'Service' : isEvent ? 'Event' : isNews ? 'News' : "" }}
          </h4>
          <h5 class="mb-3" v-if="isEdit">Edit Event</h5>
        </div>
        <form @submit.prevent="handleSubmit">
          <b-form-group>
            <!-- Title & Sub_Title -->
            <div class="mb-3">
              <b-row>
                <b-col>
                  <div>
                    <label for="title" class="font-weight-bolder">Title :</label>
                    <template v-if="isService">
                      <b-form-input v-model="servicePostForm.title" id="title" placeholder="Enter Service Title" required>
                      </b-form-input>
                    </template>
                    <template v-if="isEvent">
                      <b-form-input v-model="eventPostForm.title" id="title" placeholder="Enter Event Title" required>
                      </b-form-input>
                    </template>
                    <template v-if="isNews">
                      <b-form-input v-model="newsPostForm.title" id="title" placeholder="Enter Blog Title" required>
                      </b-form-input>
                    </template>
                  </div>
                </b-col>

                <b-col>
                  <div>
                    <label for="subTitle" class="font-weight-bolder">Sub Title :</label>
                    <template v-if="isService">
                      <b-form-input v-model="servicePostForm.sub_title" id="subTitle"
                        placeholder="Enter Service Sub Title" required>
                      </b-form-input>
                    </template>
                    <template v-if="isEvent">
                      <b-form-input v-model="eventPostForm.sub_title" id="subTitle" placeholder="Enter Event Sub Title"
                        required>
                      </b-form-input>
                    </template>
                    <template v-if="isNews">
                      <b-form-input v-model="newsPostForm.sub_title" id="subTitle" placeholder="Enter Blog Sub Title"
                        required>
                      </b-form-input>
                    </template>
                  </div>
                </b-col>
              </b-row>
            </div>

            <!-- Categories -->
            <template v-if="isService">
              <div class="mb-3">
                <label for="categories" class="font-weight-bolder">Categories :</label>
                <b-form-checkbox-group v-model="selectedCategories"
                  class="d-flex justify-content-between align-items-center" @change="changeArrToString">
                  <b-form-checkbox v-for="c in categories" :key="c.id" :value="c.id" class="h4" size="lg">{{ c.name
                  }}</b-form-checkbox>
                </b-form-checkbox-group>
                <!-- {{ servicePostForm.category_id }} -->
                <!-- <b-form-select v-model="servicePostForm.category_id" required>
                  <b-form-select-option disabled value=""
                    >Please Select a category</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="c in categories"
                    :key="c.id"
                    :value="c.id"
                    >{{ c.name }}</b-form-select-option
                  >
                </b-form-select> -->
              </div>
              <!-- <div class="mb-3">
                <label for="user" class="font-weight-bolder"
                  >User :</label
                >
                <b-form-select v-model="servicePostForm.user_id" required>
                  <b-form-select-option disabled value=""
                    >Please Select a User</b-form-select-option
                  >
                  <b-form-select-option
                    v-for="u in users"
                    :key="u.id"
                    :value="u.id"
                    >{{ u.name }}</b-form-select-option
                  >
                </b-form-select>
              </div> -->
            </template>
            <template v-if="isNews">
              <div class="mb-3">
                <label for="categories" class="font-weight-bolder">Categories :</label>
                <b-form-select v-model="newsPostForm.category_id" @change="tagsFilter" required>
                  <b-form-select-option disabled value="">Please Select a category</b-form-select-option>
                  <b-form-select-option v-for="c in categories" :key="c.id" :value="c.id">{{ c.name
                  }}</b-form-select-option>
                </b-form-select>
              </div>
            </template>
            <!-- Tags -->
            <template v-if="isNews && newsPostForm.category_id.length !== 0">
              <div class="mb-3">
                <label for="tags" class="font-weight-bolder">Tags :</label>
                <b-form-checkbox-group v-model="selectedTags" buttons
                  class="d-flex  align-items-center" @change="changeArrToString">
                  <b-form-checkbox v-for="t in tagsFilterArr" :key="t.id" :value="t.id" size="sm" class="rounded-pill mr-2 tag" button button-variant="outline-primary">
                    {{ t.name }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </div>
            </template>

            <!-- Description -->
            <template v-if="isService">
              <div class="mb-3">
                <label for="des" class="font-weight-bolder">Description :</label>
                <vue-editor v-model="servicePostForm.description"></vue-editor>
              </div>
            </template>
            <template v-if="isEvent">
              <div class="mb-3">
                <label for="des" class="font-weight-bolder">Description :</label>
                <vue-editor v-model="eventPostForm.description"></vue-editor>
              </div>
            </template>
            <template v-if="isNews">
              <div class="mb-3">
                <label for="des" class="font-weight-bolder">Description :</label>
                <vue-editor v-model="newsPostForm.description"></vue-editor>
              </div>
            </template>

            <!-- Contact Info -->
            <template v-if="!isNews">
              <div class="mb-3">
                <label for="contact-info" class="font-weight-bolder">Contact Info :</label>
                <template v-if="isService">
                  <b-form-input v-model="servicePostForm.contact_info" id="contact-info" placeholder="Enter Contact Info"
                    required>
                  </b-form-input>
                </template>
                <template v-if="isEvent">
                  <b-form-input v-model="eventPostForm.contact_info" id="contact-info" placeholder="Enter Contact Info"
                    required>
                  </b-form-input>
                </template>
              </div>
            </template>

            <template v-if="!isNews">
              <!-- Email & Phone -->
              <b-row class="mb-3">
                <b-col>
                  <div>
                    <label for="email" class="font-weight-bolder">Email :</label>
                    <template v-if="isService">
                      <b-form-input v-model="servicePostForm.email" id="email" type="email"
                        placeholder="example@gmail.com" required>
                      </b-form-input>
                    </template>
                    <template v-if="isEvent">
                      <b-form-input v-model="eventPostForm.email" id="email" type="email" placeholder="example@gmail.com"
                        required>
                      </b-form-input>
                    </template>
                  </div>
                </b-col>

                <b-col>
                  <div>
                    <label for="phno" class="font-weight-bolder">Phone :</label>
                    <template v-if="isService">
                      <b-form-input v-model="servicePostForm.phone" id="phno" placeholder="Phone Number" required>
                      </b-form-input>
                    </template>
                    <template v-if="isEvent">
                      <b-form-input v-model="eventPostForm.phone" id="phno" placeholder="Phone Number" required>
                      </b-form-input>
                    </template>
                  </div>
                </b-col>
              </b-row>

              <!-- Latitude & Longitude -->
              <div class="mb-3">
                <b-row>
                  <b-col>
                    <div>
                      <label for="lat" class="font-weight-bolder">Latitude :</label>
                      <template v-if="isService">
                        <b-form-input v-model="servicePostForm.lat" id="lat" placeholder="example - 16.768148" required>
                        </b-form-input>
                      </template>
                      <template v-if="isEvent">
                        <b-form-input v-model="eventPostForm.lat" id="lat" placeholder="example - 16.768148" required>
                        </b-form-input>
                      </template>
                    </div>
                  </b-col>

                  <b-col>
                    <div>
                      <label for="lng" class="font-weight-bolder">Longitude :</label>

                      <template v-if="isService">
                        <b-form-input v-model="servicePostForm.lng" id="lng" placeholder="example - 96.174930" required>
                        </b-form-input>
                      </template>
                      <template v-if="isEvent">
                        <b-form-input v-model="eventPostForm.lng" id="lng" placeholder="example - 96.174930" required>
                        </b-form-input>
                      </template>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </template>
            <!-- <div class="mb-3">
                <map-location-selector :zoom="15" :latitude=latitude :longitude=longitude
                  @locationUpdated="locationUpdated">
                </map-location-selector>
              </div> -->

            <!-- Booking_status & Publish_status -->
            <b-row class="mb-3">
              <b-col v-if="!isNews">
                <template v-if="isService">
                  <div>
                    <label for="status" class="font-weight-bolder">Booking Mode :</label>
                    <b-form-checkbox v-model="checked" @change="toggle(checked)" name="check-booking-button" switch>
                    </b-form-checkbox>
                  </div>
                </template>
                <template v-if="isEvent">
                  <div>
                    <label for="status" class="font-weight-bolder">Ticket Mode :</label>
                    <b-form-checkbox v-model="checked" @change="toggle(checked)" name="check-button" switch>
                    </b-form-checkbox>
                  </div>
                </template>
              </b-col>

              <b-col v-if="isNews">
                <label for="author" class="font-weight-bolder">Author :</label>
                <b-form-input v-model="newsPostForm.author" id="author" placeholder="Enter Author's Name" required>
                </b-form-input>
              </b-col>

              <b-col>
                <div>
                  <label for="status" class="font-weight-bolder">Publish :</label>
                  <b-form-checkbox v-model="publish" @change="toggle(publish)" name="check-publish-button" switch>
                  </b-form-checkbox>
                </div>
              </b-col>
            </b-row>

            <!-- States -->
            <template v-if="isService">
              <div class="mb-3">
                <label for="states" class="font-weight-bolder">States :</label>
                <b-form-select v-model="servicePostForm.state_id" required>
                  <b-form-select-option disabled value="">Please Select a state</b-form-select-option>
                  <b-form-select-option v-for="s in states" :key="s.id" :value="s.id">{{ s.name }}</b-form-select-option>
                </b-form-select>
              </div>
            </template>

            <!-- Opening_time & Closing_time -->
            <template v-if="isService">
              <b-row class="mb-3">
                <b-col>
                  <div>
                    <label for="open" class="font-weight-bolder">Opening Time :</label>
                    <b-form-timepicker v-model="openTimeVal" locale="en" @context="openContext"
                      required></b-form-timepicker>
                  </div>
                </b-col>

                <b-col>
                  <div>
                    <label for="close" class="font-weight-bolder">Closing Time :</label>
                    <b-form-timepicker v-model="closeTimeVal" locale="en" @context="closeContext"
                      required></b-form-timepicker>
                  </div>
                </b-col>
              </b-row>
            </template>

            <!-- Opening_Date & Closing_Date -->
            <template v-if="isEvent">
              <b-row>
                <b-col>
                  <div>
                    <label for="e_start" class="font-weight-bolder">Event Start Date :</label>
                    <b-form-datepicker id="e_start" v-model="eventPostForm.opening_date" class="mb-2"
                      required></b-form-datepicker>
                  </div>
                </b-col>

                <b-col>
                  <div>
                    <label for="e_end" class="font-weight-bolder">Event End Date :</label>
                    <b-form-datepicker id="e_end" v-model="eventPostForm.closing_date" class="mb-2"
                      required></b-form-datepicker>
                  </div>
                </b-col>
              </b-row>
            </template>

            <!-- Photos -->
            <div v-if="!isEdit" class="mb-4">
              <label for="contact-info" class="font-weight-bolder">Photos :</label>
              <div>
                <MyPhotoUpload @getPhotoId="uploadPhoto" />
              </div>
            </div>
          </b-form-group>

          <div class="w-100 text-center" v-if="!isEdit">
            <b-button variant="primary" class="col-6" type="submit">
              <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
              <span v-else>Submit</span>
            </b-button>
          </div>
        </form>

        <div class="w-100 text-center" v-if="isEdit">
          <b-button type="button" variant="primary" @click="updateServiceData(servicePostForm.id)" class="col-6">
            <b-spinner variant="white" v-if="spinner === true" label="Text Centered"></b-spinner>
            <span v-else>Update</span>
          </b-button>
        </div>
      </b-card>
    </div>
  </div>
</template>
  
<script>
import store from "@/store";
// import MapLocationSelector from 'vue-latlng-picker';
import Table from "../components/Table.vue";
import { VueEditor } from "vue2-quill-editor";
import MyPhotoUpload from "../components/MyPhotoUpload.vue";
import FCategoriesCom from "../components/FCategoriesCom.vue";
import FStatesCom from "../components/FStatesCom.vue";
import FSearchInput from "../components/FSearchInput.vue";
import router from "@/router";
import { useRouter } from "@core/utils/utils";
import {
  ref,
  reactive,
  computed,
  watch,
  // ref, watch, computed, onUnmounted,
} from "@vue/composition-api";
import {
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BDropdown,
  BDropdownItem,
  BModal,
  BFormSelect,
  BFormSelectOption,
  BFormTextarea,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormTimepicker,
  BFormDatepicker,
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BModal,
    BFormSelect,
    BFormSelectOption,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormTimepicker,
    BFormDatepicker,
    // MapLocationSelector,
    VueEditor,
    Table,
    MyPhotoUpload,
    FCategoriesCom,
    FStatesCom,
    FSearchInput,
  },
  setup(props, { emit }) {
    const { route, router } = useRouter();
    const routerQuery = router.app._route.query;
    const fields = [
      { key: "id", label: "#" },
      { key: "title", label: "title" },
      { key: "sub_title", label: "sub title" },
      { key: "category.name", label: "category name" },
      { key: "actions", label: "actions" },
    ];
    const spinner = ref(false);
    const checked = ref(true);
    const publish = ref(true);
    const categories = ref(JSON.parse(routerQuery.categories));
    const isService = ref(JSON.parse(routerQuery.isService));
    const isEvent = ref(JSON.parse(routerQuery.isEvent));
    const isNews = ref(JSON.parse(routerQuery.isNews));
    const states = ref(isService.value ? JSON.parse(routerQuery.states) : "");
    const tags = ref(isNews.value ? JSON.parse(routerQuery.tags) : "");
    const tagsFilterArr = ref([]);
    // const users = ref(isService.value ? JSON.parse(routerQuery.users) : "");
    const isEdit = ref(JSON.parse(routerQuery.isEdit));
    const mulImgs = ref([]);
    const openTimeVal = ref(null);
    const closeTimeVal = ref(null);
    const selectedCategories = ref([]);
    const selectedTags = ref([]);
    const servicePostForm = reactive({
      id: "",
      title: "",
      category_id: [],
      // user_id: "",
      sub_title: "",
      description: "",
      contact_info: "",
      phone: "",
      email: "",
      lat: "",
      lng: "",
      booking_mode: 1,
      status: "publish",
      state_id: "",
      opening_time: "",
      closing_time: "",
      photos: [],
    });
    const eventPostForm = reactive({
      id: "",
      title: "",
      category_id: 0,
      sub_title: "",
      description: "",
      contact_info: "",
      email: "",
      phone: "",
      lat: "",
      lng: "",
      ticket_mode: 1,
      status: "publish",
      photos: [],
      opening_date: "",
      closing_date: "",
    });
    const newsPostForm = reactive({
      id: "",
      title: "",
      category_id: "",
      sub_title: "",
      description: "",
      author: "",
      status: "publish",
      tags: [],
      photos: [],
    });

    //Datas Fetching
    //Functions
    // const locationUpdated = (latlng) => {
    //   latitude.value = latlng.lat;
    //   longitude.value = latlng.lng;
    // };
    // tags filter
    const tagsFilter = () => {
      tagsFilterArr.value = tags.value.filter(tag => {
        return newsPostForm.category_id == tag.category_id;
      })
      console.log(tagsFilterArr.value)
    }
    // service categories
    const changeArrToString = () => {
      if (isService.value) {
        servicePostForm.category_id = JSON.stringify(selectedCategories.value);
      }
      if (isNews.value) {
        newsPostForm.tags = JSON.stringify(selectedTags.value);
      }
    };
    const toggle = (c = checked.value, p = publish.value) => {
      if (isService.value) {
        c
          ? (servicePostForm.booking_mode = 1)
          : (servicePostForm.booking_mode = 0);
        p
          ? (servicePostForm.status = "publish")
          : (servicePostForm.status = "unpublish");
      }
      if (isEvent.value) {
        c ? (eventPostForm.ticket_mode = 1) : (eventPostForm.ticket_mode = 0);
        p
          ? (eventPostForm.status = "publish")
          : (eventPostForm.status = "unpublish");
      }
      if (isNews.value) {
        p
          ? (newsPostForm.status = "publish")
          : (newsPostForm.status = "unpublish");
      }
    };
    const uploadPhoto = (photoIds) => {
      mulImgs.value = [...mulImgs.value, photoIds];
      let multiplePhotos = mulImgs.value;
      if (isService.value) {
        servicePostForm.photos = JSON.stringify(multiplePhotos);
      }
      if (isEvent.value) {
        eventPostForm.photos = JSON.stringify(multiplePhotos);
      }
      if (isNews.value) {
        newsPostForm.photos = JSON.stringify(multiplePhotos);
      }
    };
    // Time
    const openContext = (ctx) => {
      servicePostForm.opening_time = ctx.value.slice(0, 5);
    };
    const closeContext = (ctx) => {
      servicePostForm.closing_time = ctx.value.slice(0, 5);
    };
    // Clear Datas
    const clearDatas = () => {
      if (isService.value) {
        servicePostForm.id = "";
        servicePostForm.title = "";
        servicePostForm.category_id = [];
        // servicePostForm.user_id = "";
        servicePostForm.sub_title = "";
        servicePostForm.description = "";
        servicePostForm.contact_info = "";
        servicePostForm.phone = "";
        servicePostForm.email = "";
        servicePostForm.lat = "";
        servicePostForm.lng = "";
        servicePostForm.booking_mode = 1;
        servicePostForm.photos = [];
      }
      if (isEvent.value) {
        eventPostForm.title = "";
        eventPostForm.category_id = 0;
        eventPostForm.sub_title = "";
        eventPostForm.description = "";
        eventPostForm.contact_info = "";
        eventPostForm.phone = "";
        eventPostForm.email = "";
        eventPostForm.lat = "";
        eventPostForm.lng = "";
        eventPostForm.ticket_mode = 1;
        eventPostForm.opening_date = "";
        eventPostForm.closing_date = "";
      }
      if (isNews.value) {
        newsPostForm.title = "";
        newsPostForm.category_id = "";
        newsPostForm.sub_title = "";
        newsPostForm.description = "";
        newsPostForm.author = "";
        newsPostForm.status = "publish";
      }
    };

    //Data Posting
    const handleSubmit = () => {
      spinner.value = true;
      if (isService.value) {
        store
          .dispatch("app-mypages/postDatas", {
            path: "services",
            payload: servicePostForm,
          })
          .then((response) => {
            spinner.value = false;
            isEdit.value = false;
            clearDatas();
            router.go(-1);
          });
      }
      if (isEvent.value) {
        store
          .dispatch("app-mypages/postDatas", {
            path: "events",
            payload: eventPostForm,
          })
          .then((response) => {
            spinner.value = false;
            isEdit.value = false;
            clearDatas();
            router.go(-1);
          });
      }
      if (isNews.value) {
        store
          .dispatch("app-mypages/postDatas", {
            path: "news",
            payload: newsPostForm,
          })
          .then((response) => {
            spinner.value = false;
            isEdit.value = false;
            clearDatas();
            router.go(-1);
          });
      }
    };

    // Data Editing
    if (isEdit.value) {
      const editServiceData = () => {
        let editDatas = JSON.parse(routerQuery.editDatas);
        // console.log(routerQuery.editDatas);
        // console.log(editDatas);

        if (isService.value) {
          servicePostForm.id = editDatas.id;
          servicePostForm.title = editDatas.title;
          servicePostForm.category_id = editDatas.category_id;
          // servicePostForm.user_id = editDatas.user_id;
          servicePostForm.sub_title = editDatas.sub_title;
          servicePostForm.description = editDatas.description;
          servicePostForm.contact_info = editDatas.contact_info;
          servicePostForm.phone = editDatas.phone;
          servicePostForm.email = editDatas.email;
          servicePostForm.lat = editDatas.lat;
          servicePostForm.lng = editDatas.lng;
          servicePostForm.booking_mode = editDatas.booking_mode;
          servicePostForm.status = editDatas.status;
          servicePostForm.state_id = editDatas.state_id;
          openTimeVal.value = editDatas.opening_time;
          closeTimeVal.value = editDatas.closing_time;
        }

        if (isEvent.value) {
          eventPostForm.id = editDatas.id;
          eventPostForm.title = editDatas.title;
          eventPostForm.category_id = 0;
          eventPostForm.sub_title = editDatas.sub_title;
          eventPostForm.description = editDatas.description;
          eventPostForm.contact_info = editDatas.contact_info;
          eventPostForm.phone = editDatas.phone;
          eventPostForm.email = editDatas.email;
          eventPostForm.lat = editDatas.lat;
          eventPostForm.lng = editDatas.lng;
          eventPostForm.ticket_mode = editDatas.ticket_mode;
          eventPostForm.opening_date = editDatas.opening_date;
          eventPostForm.closing_date = editDatas.closing_date;
        }

        if (isNews.value) {
          newsPostForm.id = editDatas.id;
          newsPostForm.title = editDatas.title;
          newsPostForm.category_id = editDatas.category_id;
          newsPostForm.sub_title = editDatas.sub_title;
          newsPostForm.description = editDatas.description;
          newsPostForm.author = editDatas.author;
        }
      };
      editServiceData();
    }
    const updateServiceData = () => {
      spinner.value = true;
      if (isService.value) {
        store
          .dispatch("app-mypages/updateData", {
            path: "services",
            payload: servicePostForm,
          })
          .then((response) => {
            spinner.value = false;
            isEdit.value = false;
            clearDatas();
            router.go(-1);
          });
      }
      if (isEvent.value) {
        store
          .dispatch("app-mypages/updateData", {
            path: "events",
            payload: eventPostForm,
          })
          .then((response) => {
            spinner.value = false;
            isEdit.value = false;
            clearDatas();
            router.go(-1);
          });
      }
      if (isNews.value) {
        store
          .dispatch("app-mypages/updateData", {
            path: "news",
            payload: newsPostForm,
          })
          .then((response) => {
            spinner.value = false;
            isEdit.value = false;
            clearDatas();
            router.go(-1);
          });
      }
    };

    // Data Deleting
    // const delServiceData = (id) => {
    //   spinner.value = true;
    //   if (isService.value) {
    //     store
    //       .dispatch("app-mypages/delData", { path: "services", id })
    //       .then((response) => {
    //         spinner.value = false;
    //       });
    //   }
    // };

    // Close Function
    const close = () => {
      isEdit.value = false;
      clearDatas();
    };

    return {
      fields,
      spinner,
      isEdit,
      isService,
      isEvent,
      isNews,
      checked,
      publish,
      categories,
      states,
      tags,
      tagsFilterArr,
      tagsFilter,
      // users,
      handleSubmit,
      toggle,
      uploadPhoto,
      openTimeVal,
      openContext,
      closeTimeVal,
      closeContext,
      servicePostForm,
      selectedCategories,
      selectedTags,
      changeArrToString,
      eventPostForm,
      newsPostForm,
      selectedTags,
      // editServiceData,
      updateServiceData,
      // delServiceData,
      close,
      // locationUpdated,
    };
  },
};
</script>
<style scoped>
.tag {
  padding: 0.5rem 1.5rem;
  flex: 0 0 auto;
}
</style>